<div
	class="sl-snackbar-wrapper sl-width-100 sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-space-between sl-flexbox-gap-8"
	data-testid="snackbar-component-element" [data-testid-context]="data.type">
	<div class="sl-snackbar-content">
		@if (data.type === SnackbarType.loader) {
			<div class="sl-snackbar-loader" data-testid="snackbar-loader-element">
				<mat-spinner color="red" diameter="18"></mat-spinner>
			</div>
		}
		<span data-testid="snackbar-message-element">{{ data.message }}</span>
	</div>
	@if (!!data.action && data.type !== SnackbarType.loader) {
		<button	mat-button
			type="button"
			class="sl-snackbar-action"
			data-testid="snackbar-action-element"
			(click)="action()">
			{{ data.action }}
		</button>
	}
	@if (data.showDismiss) {
		<mat-icon class="material-symbols-outlined clickable"
			data-testid="snackbar-close-element"
			(click)="dismiss()">
			close
		</mat-icon>
	}
</div>
